import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_3.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_3.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_3.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Reasons why digital transformations fail and how digital workers come to the rescue.",
    url:
      "https://blog.workativ.com/digital-workers-to-the-rescue-reasons-why-digital-transformations-fail-and-how-ai-powered-chatbots-with-automated-workflows-can-help/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "For achieving higher ROI, implement a next-gen workplace support solution.",
    url: "/conversational-ai-platform/innovative-technology",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "How to create an intelligent Helpdesk Chatbot with Zero coding",
    url: "https://youtu.be/K_lyi-vVASU",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Help desk efficiency and cost of support",
    content:
      "Cost per ticket is a measure of how efficiently a service desk conducts its business.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "Innovation in business",
    content: "Organizations need an innovative solution that helps cut costs.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "How can Workativ Assistant help you cut support costs?",
    content:
      "The automated resolution capability of Workativ Assistant enables employees to resolve issues without contacting support agents.",
    backgroundClass: "card_three"
  }
];
const menu = [
  {
    url: "Helpdesk efficiency",
    href: "#section-1"
  },
  {
    url: "Innovation in business",
    href: "#section-2"
  },
  {
    url: "How to cut support costs",
    href: "#section-3"
  }
];
export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="Reduce costs of delivering HR & IT support with Conversational AI Chatbot | Automate Employee Support"
        description="Reduce IT Helpdesk costs by implementing Conversational AI Chatbot and Process Automation to deliver IT & HR support."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Reduce costs of delivering HR & IT support with Conversational AI Chatbot | Automate Employee Support"
        ogDescription="Reduce IT Helpdesk costs by implementing Conversational AI Chatbot and Process Automation to deliver IT & HR support."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="gen_vs_true cost_reduction">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Cost Reduction
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    An improvised helpdesk provides numerous cost benefits and
                    savings
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
                
              </section>
              <div>
                <section className=" wv-section-mv-p width_container pb-200 content_para">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                          Businesses today need to continually innovate to stand
                          out and deliver value for their customers. As markets
                          become more competitive, companies face tremendous
                          pressure to reduce operating costs to be profitable.
                          Also, as innovation is vital in differentiating
                          successful businesses from unsuccessful ones, the
                          overall costs incurred to reach the top starts to
                          matter.
                        </p>
                        <hr className="wv-limited-content mx-auto wv-section-mv-m" />
                        <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                          Businesses today need to continually innovate to stand
                          out and deliver value for their customers. As markets
                          become more competitive, companies face tremendous
                          pressure to reduce operating costs to be profitable.
                          Also, as innovation is vital in differentiating
                          successful businesses from unsuccessful ones, the
                          overall costs incurred to reach the top starts to
                          matter.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <Cards cards={cardData} />
                <section className="wv-main-content wv-section-mv-p0">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                        <div className="dropdown wv-dropdown">
                          <button
                            className={`link ${isOpen ? "active" : ""}`}
                            onClick={() => setOpen(!isOpen)}
                          >
                            Explore this articles
                          </button>
                          <div
                            id="myDropdown"
                            className={`accordion-item submenu dropdown-content ${
                              !isOpen ? "collapsed" : ""
                            }`}
                          >
                            <li>
                              {menu.map(data => (
                                <AnchorLink
                                  offset={280}
                                  className="url_manipulation"
                                  href={data.href}
                                >
                                  {data.url}
                                </AnchorLink>
                              ))}
                            </li>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 --hd-sm">
                        <aside
                          className="wv-sidenav max-width-255"
                          id="myHeader"
                        >
                          <p className="wv-sm-heading">Explore this article</p>
                          <ul className="">
                            <li>
                              <AnchorLink
                                offset={() => 100}
                                href="#section-1"
                                className={
                                  isActive === "Helpdesk efficiency"
                                    ? "url_manipulation active"
                                    : "url_manipulation"
                                }
                                onClick={() => {
                                  setIsActive("Helpdesk efficiency");
                                }}
                              >
                                {" "}
                                Helpdesk efficiency
                              </AnchorLink>
                            </li>
                            <li>
                              <AnchorLink
                                offset={() => 100}
                                href="#section-2"
                                className={
                                  isActive === "Innovation in business"
                                    ? "url_manipulation active"
                                    : "url_manipulation"
                                }
                                onClick={() => {
                                  setIsActive("Innovation in business");
                                }}
                              >
                                {" "}
                                Innovation in business
                              </AnchorLink>
                            </li>
                            <li>
                              <AnchorLink
                                offset={() => 100}
                                href="#section-3"
                                className={
                                  isActive === " How to cut support costs"
                                    ? "url_manipulation active"
                                    : "url_manipulation"
                                }
                                onClick={() => {
                                  setIsActive(" How to cut support costs");
                                }}
                              >
                                {" "}
                                How to cut support costs
                              </AnchorLink>
                            </li>
                          </ul>
                          <AnchorLink offset={200} href="#scrollToHome">
                            {" "}
                            Back to top ↑
                          </AnchorLink>
                        </aside>
                      </div>
                      <div className="col-md-9 col-sm-12 col-xs-12">
                        <div className="wv-content-area">
                          <section
                            className="wv-content-section"
                            id="section-1"
                          >
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">
                                  Conventional helpdesk efficiency and cost of
                                  support
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  While many companies seem to be doing well (on
                                  paper) following a traditional helpdesk model,
                                  a considerable number of organizations include
                                  reduced helpdesk and IT support costs in their
                                  agenda. 35% of them have this under their list
                                  of initiatives for 2019, according to a survey
                                  conducted by GatePoint Research.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  When referring to helpdesk costs, tickets, and
                                  support delivery are the areas that prove most
                                  challenging for most organizations.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  Cost per ticket is a measure of how
                                  efficiently a helpdesk conducts its business.
                                  It has several key drivers, of which the most
                                  important are agent utilization and ticket
                                  handle time. Helpdesk support is a
                                  labor-intensive function with the vast
                                  majority of costs being personnel-related, and
                                  the cost breakdown for support is constant for
                                  countries and region to region.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  Let's take a look at some of the costs that
                                  support incurs.
                                </p>
                                <ul>
                                  <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                    The average cost per ticket of level 1
                                    support is $22
                                  </li>
                                  <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                    Industry experts have reported the cost of a
                                    single technical support call to be as much
                                    as $45
                                  </li>
                                  <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                    The average hourly rate for Technical
                                    Support Analyst ranges from $25 to $32 with
                                    the average hourly pay of $29
                                  </li>
                                </ul>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  To cut costs, most companies stick to
                                  confining help to a knowledge base to help
                                  resolve IT issue faster. A few companies now
                                  have chatbots that can fetch the information
                                  more quickly. But these come with the
                                  limitations to finding the right answers to
                                  resolve an issue.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content m-b_30">
                                  Accessing a knowledge base always to resolve
                                  issues is by no means a quick method, and
                                  users would rather place a call to the
                                  helpdesk instead. This model would not support
                                  a reduction in the number of calls made to the
                                  helpdesk.
                                </p>
                              </div>
                            </div>
                          </section>
                        </div>
                        <section
                          className="bg_section_knowledge left_to_right width_container"
                          id="section-1"
                        >
                          <div className="">
                            <div className="row">
                              <div className="col pr-0 pl-0  -call-reflection">
                                <div className="pad_40px">
                                  <div className="main-slider-left">
                                    <h1>
                                      An innovative solution that combines AI +
                                      Automation to give employees the perfect
                                      virtual assistant that automates
                                      resolution.
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="width_container">
                          <div className="container">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="wv-content-area">
                                  <section
                                    className="wv-content-section mb-2"
                                    id="section-2"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="--sh-sm mt-3 mb-4 wv-banner-text-medium">
                                          An innovative solution that combines
                                          AI + Automation to give employees the
                                          perfect virtual assistant that
                                          automates resolution.
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          Innovation plays a significant role in
                                          business, but cost to innovate can be
                                          prohibitive for companies. Also, many
                                          companies assume that increasing
                                          workforce resources is the solution,
                                          but this only ends up adding on extra
                                          costs. Organizations need an
                                          innovative solution that helps cut
                                          costs.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          If companies embrace innovative
                                          solutions, they will be able to bring
                                          down the cost per ticket and keep
                                          support teams small. It will be a
                                          powerful impetus to companies that are
                                          constrained by small budgets for the
                                          IT helpdesk.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          Workativ Assistant is an innovative
                                          solution that combines AI +
                                          automation, aiding in making
                                          self-service user-friendly.
                                          Furthermore, automating resolution
                                          empowers employees to resolve
                                          workplace issues autonomously. We
                                          would call this: new-age AI- driven
                                          self-service with automated
                                          resolution.
                                        </p>
                                        <div className="tree_li">
                                          <h4 className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                            Why is self-service related to cost
                                            you may ask?{" "}
                                          </h4>
                                          <ul>
                                            <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                              Are your internal support requests
                                              outgrowing the capacity that your
                                              IT support team can handle?
                                            </li>
                                            <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                              Is there a pile-up and backlog of
                                              unresolved queries/requests?
                                            </li>
                                            <li className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                              Are your IT support agents
                                              handling repetitive requests every
                                              day? (e.g., password reset?)
                                            </li>
                                          </ul>
                                        </div>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          If your organization has seen a
                                          decline in productivity due to long
                                          resolution times for requests, you
                                          would be looking for more
                                          cost-effective ways of delivering
                                          support. And, self-service has shown
                                          to be more efficient, saves costs, and
                                          provides a superior experience for
                                          employees.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          Data shows that self-service program
                                          users attain a 3.0% annual decrease in
                                          service costs, compared to a 0.7%
                                          increase by non-users.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="pb-210 width_container">
                          <div className="container">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="wv-content-area">
                                  <section
                                    className="wv-content-section"
                                    id="section-3"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="wv-heading-h4 mb-2 mt-40px">
                                          How can Workativ Assistant help you
                                          cut support costs?
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          The automated resolution capability of
                                          Workativ Assistant enables employees
                                          to resolve issues without contacting
                                          support agents.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          Further, there are more ways in which
                                          to reduce costs by implementing
                                          Workativ Assistant for IT support:
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                        <img loading = "lazy"
                                          src={tick_icon}
                                          rel="Tick icon"
                                          height={32}
                                          width={32}
                                          className="mb-4 max-width-35"
                                        />
                                        <h4 className="wv-heading-h4 mb-2">
                                          Call deflection success
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          User-friendly, intelligent virtual
                                          assistant as a self-help solution
                                          instead of an online static helpdesk
                                          or knowledge base.
                                        </p>
                                      </div>
                                      <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                        <img loading = "lazy"
                                          src={tick_icon}
                                          rel="Tick icon"
                                          height={32}
                                          width={32}
                                          className="mb-4 max-width-35"
                                        />
                                        <h4 className="wv-heading-h4 mb-2">
                                          Improved ticket handling
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          L1 issues get resolved faster,
                                          autonomously.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                        <img loading = "lazy"
                                          src={tick_icon}
                                          rel="Tick icon"
                                          height={32}
                                          width={32}
                                          className="mb-4 max-width-35"
                                        />
                                        <h4 className="wv-heading-h4 mb-2">
                                          Solving high-value problems
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          Agent utilization will improve
                                        </p>
                                      </div>
                                      <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                        <img loading = "lazy"
                                          src={tick_icon}
                                          rel="Tick icon"
                                          height={32}
                                          width={32}
                                          className="mb-4 max-width-35"
                                        />
                                        <h4 className="wv-heading-h4 mb-2">
                                          Helpdesk optimization
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                                          It does not require an increase in
                                          support resources and potential cost
                                          take out.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mt-2">
                                          Companies can build virtual assistant
                                          with out of the box automation
                                          capabilities in a few days, to
                                          automate the resolution of common
                                          issues and requests. Automated
                                          resolution considerably reduces costs
                                          because employees will not be
                                          dependent upon internal support agents
                                          to solve issues. For every ticket
                                          automated with Workativ Assistant, you
                                          save in the range of 40-60% or so in a
                                          month depending on current help desk
                                          spend and ticket drivers.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
